<template>
  <section class="main">
    <div class="container">
      <div class="left">
        <div class="box">
          <div>
            <img src="../assets/home/small-logo.png" alt="" />
            <span>字景大模型</span>
          </div>
          <div class="new">+新建</div>
        </div>
      </div>
      <div class="right">
        <div class="content">
          <div class="chat-messages">
            <div class="first">
              <div>
                <img src="../assets/home/small-logo.png" alt="" />
              </div>
              <div class="box">
                <span>你好，我是字景大模型</span>
                <p>欢迎向我提问</p>
              </div>
            </div>
            <div
              v-for="(message, index) in messages"
              :key="index"
              style="white-space: pre-wrap"
            >
              <!-- <div class="askTime" v-show="message.isUser">
                {{ message.time }}
              </div> -->
              <div
                v-show="message.isUser"
                class="message"
                :class="{ 'user-message': message.isUser }"
              >
              <img src="../assets/home/avatar.png" alt="">
                <span> {{ message.text }}</span>
              </div>
              <div v-show="!message.isUser" class="kefu">
               
                <!-- <span class="kefuTime"> {{ message.time }}</span> -->
                <div class="replay">
                  <img
                  class="kefuAvatar"
                  src="../assets/home/small-logo.png"
                  alt=""
                />
                  <span style="margin: 20px 10px;">{{ message.replay }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="askInput">
          <textarea
            class="textarea"
            v-model="newMessage"
            @keyup.enter="sendMessage"
            placeholder="很高兴为您服务，请描述您的问题"
          />
          <img
            class="send"
            @click="sendMessage"
            src="../assets/drive/send.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
</template>
      
<script>
// 在页面加载完成后执行
window.onload = function () {
  // 将滚动条置于顶部
  setTimeout(function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};
export default {
  data() {
    return {
      // 当前时间
      time: "",
      ModelUrl: "",
      messages: [],
      newMessage: "",
    };
  },
  created() {
    let that = this;
    this.setTime = setInterval(() => {
      that.Date();
    }, 1000);
  },
  mounted() {
    this.request3dModel();
  },

  methods: {
    sendMessage() {
      this.Date();
      if (this.newMessage.trim() !== "") {
        this.messages.push({
          text: this.newMessage,
          isUser: true,
          time: this.time,
        });
        this.newMessage = "";
        // 在实际应用中，你可以在这里发送消息给客服
        setTimeout(() => {
          this.messages.push({
            replay: "稍等片刻,答案正在为您生成中~",
            isUser: false,
            time: this.time,
          });
        }, 1000);
      }
    },
    sendQuickReply(reply) {
      this.messages.push({ text: reply, isUser: false });
      // 在实际应用中，你可以在这里发送快捷回复给客服
    },
    request3dModel() {
      var param = {
        deviceId: this.deviceId,
      };
      this.$post("/device3dPhoto/list", param)
        .then((res) => {
          // 主要参数
          //   console.log(res);
          if (res.code == 1) {
            console.log(res.data);
            if (res.data.length > 0) {
              this.ModelUrl = res.data[0].url;
            } else {
              this.$message.warning("暂无3d模型");
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },

    // 返回按钮
    ToMap() {
      this.$router.push("/map");
    },
    // 获取时间戳
    Date() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        hours: nowDate.getHours(),
        minutes: nowDate.getMinutes(),
        seconds: nowDate.getSeconds(),
      };
      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
      const newday = date.date >= 10 ? date.date : "0" + date.date;
      const newHours = date.hours >= 10 ? date.hours : "0" + date.hours;
      const newminutes = date.minutes >= 10 ? date.minutes : "0" + date.minutes;
      const newseconds = date.seconds >= 10 ? date.seconds : "0" + date.seconds;

      this.time =
        date.year +
        "年" +
        newmonth +
        "月" +
        newday +
        "日" +
        newHours +
        ":" +
        newminutes +
        ":" +
        newseconds;
    },

    // 退出登录
    logout() {
      this.$get("/user/logout")
        .then((res) => {
          if (res.code == 1) {
            this.$router.push("/Login");
            this.$message({
              type: "success",
              message: "登出成功!",
            });
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 退出登录取消
    cancelLogout() {
      this.visible = false;
      this.$message({
        type: "info",
        message: "已取消登出",
      });
    },
  },
  beforeDestroy() {
    // 清除定时器
    if (this.setTime) {
      clearInterval(this.setTime);
    }
  },
};
</script>
      
    
<style scoped lang="scss">
.container {
  display: flex;
  .left {
    background: #e9e9f4;
    width: 30%;
    height: 100vh;
    .box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px;
      background: #e4e2ee;
      padding: 10px 5px;
      box-sizing: border-box;
      border-radius: 25px;
      img {
        vertical-align: middle;
        width: 40px;
        margin-right: 10px;
      }
      span {
        vertical-align: middle;
      }
      .new {
        color: #4955f5;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .right {
    background: #ebeef7;
    width: 70%;
    height: 100vh;
    position: relative;
    .chat-messages {
      margin: 20px;
      .first {
        display: flex;
        // align-items: center;
        img {
          width: 40px;
        }
        .box {
          background: #fff;
          padding: 10px;
          box-sizing: border-box;
          margin: 0 10px;
          border-radius: 5px;
          span {
            font-size: 18px;
            font-weight: 600;
            // color: #ccc;
          }
          p {
            margin: 10px 0;
            color: #b3b5c8;
          }
        }
      }
      .message {
        border-radius: 5px;
        box-sizing: border-box;
        white-space: pre-wrap;
        span{
          vertical-align: middle;
          margin: 0 10px;
        }
        img{
          vertical-align: middle;
          width: 35px;
        }
      }
      .askTime {
        color: #333333;
      }
      .user-message {
        color: #fff;
        white-space: wrap;
        margin: 10px 0;
        span {
          // background-color: #007bff;
          background-color: #ccc;
          color: #000;
          padding: 5px;
          border-radius: 5px;
          box-sizing: border-box;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .agent-message {
        background-color: #eee;
        float: left;
        clear: both;
      }
      // 客服回复
      .kefu {
        color: #333333;

        img {
          vertical-align: middle;
           width: 38px;
           border-radius: 50%;
        }
        span {
          vertical-align: middle;
        }
        .replay {
          margin: 20px 0;
          span {
            background: #fff;
            border-radius: 3px;
            padding: 5px;
            margin: 10px 0;
            display: inline-block;
          }
        }
      }
    }
    .askInput {
      width: 80%;
      min-height: 120px;
      border: 1px solid #ccc;
      border-radius: 5px;
      position: absolute;
      bottom: 30px;
      left: 10%;
      background: #fff;
      .textarea {
        width: 100%;
        min-height: 80px;
        font-size: 16px;
        padding: 5px;
        box-sizing: border-box;
      }
      textarea {
        border: none;
        resize: none;
      }
      textarea:focus,
      textarea:active {
        border: none;
        outline: none;
      }
      textarea::-webkit-input-placeholder {
        color: #ccc;
        font-size: 16px;
        padding: 5px;
        box-sizing: border-box;
      }
      .send {
        position: absolute;
        width: 30px;
        right: 3px;
        bottom: 2px;
      }
    }
  }
}
</style>
    
    